<template>
  <div class="app-wrapper openSidebar">
    <navbar v-show="!isOper" />
    <liftside
      v-show="!isOper"
      class="sidebar-container"
      :class="isCollapse ? 'hide-container' : ''"
    />
    <div
      :class="[
        !isOper ? 'main-container' : '',
        isCollapse ? 'hideSidebar' : ''
      ]"
    >
      <section class="app-main">
        <!-- <transition name="fade" mode="out-in"> -->
        <breadcrumb />
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive" />
        </keep-alive>
        <!-- </transition> -->
        <!-- <transition name="fade" mode="out-in"> -->
        <router-view v-if="!$route.meta.keepAlive" />
        <!-- </transition> -->
        <v-footer />
      </section>
    </div>
  </div>
</template>

<script>
import { Navbar, Liftside, vFooter, Breadcrumb } from 'components';
import { mapGetters } from 'vuex';

export default {
  name: 'Layout',
  components: {
    Navbar,
    Liftside,
    vFooter,
    Breadcrumb
  },
  data() {
    return {
      isOper: false
    };
  },
  computed: {
    ...mapGetters({
      isCollapse: 'getCollapseState'
    })
  },
  created() {
    // alert(this.$route.query.p)
    if (this.$route.query.p == 'oper') {
      this.isOper = true;
    }
  },
  methods: {}
};
</script>

<style rel="stylesheet/less" lang="less">
// @import "src/styles/mixin.less";
.app-wrapper {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
  position: relative;
  height: 100%;
  width: 100%;
  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}
.sidebar-container {
  margin-top: 50px;
}

.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}
.app-main {
  /*50 = navbar  */
  margin-top: 50px;
  min-height: calc(100vh - 50px);
  position: relative;
  overflow: hidden;
  padding: 0 15px 15px 15px;
  background-color: #eff0f4;
  height: 100%;
}
/deep/.el-button {
  padding: 20px !important;
}
</style>

/**
 * 格式化金额过滤器
 * @param {*} num 
 * @returns 
 */
let NumFormat = num => {
  if (!num) return '0.00'

  if (num) {
    //   num = num.toString().split('.')[0];
    // eslint-disable-next-line no-useless-escape
    num = parseInt(num).toString().replace(/\$|\,/g, '');
    if (num === '' || isNaN(num)) {
      return '';
    }
    var sign = num.indexOf('-') > -1 ? '-' : '';
    if (num.indexOf('-') > -1) {
      num = num.substring(1);
    }
    var cents = num.indexOf('.') > 0 ? num.substr(num.indexOf('.')) : '';
    cents = cents.length > 1 ? cents : '';
    num = num.indexOf('.') > 0 ? num.substring(0, num.indexOf('.')) : num;
    if (cents === '') {
      if (num.length > 1 && num.substr(0, 1) === '0') {
        return '';
      }
    } else {
      if (num.length > 1 && num.substr(0, 1) === '0') {
        return '';
      }
    }
    for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++) {
      num = num.substring(0, num.length - (4 * i + 3)) + ',' + num.substring(num.length - (4 * i + 3));
    }
    return sign + num + cents;
  } else {
    return '0';
  }
}
export { NumFormat }

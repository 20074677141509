<template>
  <el-breadcrumb class="app-breadcrumb" separator=">">
    <el-breadcrumb
      style="display:inline-block"
      separator-class="el-icon-arrow-right"
    >
      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
      <el-breadcrumb-item v-for="item in lists" :key="item.name">
        {{ item.name }}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </el-breadcrumb>
</template>

<script>
export default {
  data() {
    return {
      levelList: null,
      lists: [],
      names: null
    };
  },
  watch: {
    $route(to, from) {
      this.lists = [];
      const meta = to.meta;
      console.log(meta);
      for (const key in meta) {
        console.log(key);
        this.lists.push({ name: meta[key] });
      }
      this.name = to.meta.parent + '>' + to.meta.title;
      console.log(this.name);
    }
  },
  created() {},
  mounted() {
    for (const key in this.$route.meta) {
      this.lists.push({ name: this.$route.meta[key] });
    }
  },
  methods: {}
};
</script>

<style lang="less" type="text/less" rel="stylesheet/less" scoped>
.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 10px;
  .no-redirect {
    color: #97a8be;
    cursor: text;
  }
}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueI18n from 'vue-i18n'
import store from './store'
import api from './api'
import '@/assets/styles/index.less'
import 'normalize.css/normalize.css'
import '@/assets/styles/theme/index.css';
// import Businesslist from './views/businessmanagement/Businesslist.vue' //企业信息修改组件
// import enterpriseInfo from './views/businessmanagement/enterpriseInfo.vue' //企业信息审核
// import invoiceinfo from './views/businessmanagement/invoiceinfo.vue'
// import bankInfo from './views/businessmanagement/bankInfo.vue'
import * as filters from './filters';
// import addDiscount from './views/discountManage/addDiscount.vue'
// import redactaudit from './views/discountManage/redactaudit.vue'
// import modifyPrice from './views/modifyPriceManage/modifyPrice.vue'
// import contractAudit from './views/contractManage/contractAudit.vue'
// import contractList from './views/contractManage/contractList.vue'
// import addContract from './views/contractManage/addContract.vue'
// import checkContract from './views/contractManage/checkContract.vue'
// import contractCreditAudit from './views/contractManage/contractCreditAudit.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en'
ElementUI.Dialog.props.closeOnClickModal.default = false
Vue.use(ElementUI, {
  locale
})
Vue.use(VueI18n)
Vue.use(api)
// Vue.component('Businesslist', Businesslist)
// Vue.component('enterpriseInfo', enterpriseInfo)
// Vue.component('invoiceinfo', invoiceinfo)
// Vue.component('bankInfo', bankInfo)

import qs from 'qs'
Vue.prototype.$qs = qs

// undefined和null转为空白字符串
Vue.prototype.$praseStrEmpty = function (str) {
  if (typeof str === 'undefined' || str === null) {
    return '';
  }
  return str;
}

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard);

// Vue.component('addDiscount', addDiscount)
// Vue.component('redactaudit', redactaudit)
// Vue.component('modifyPrice', modifyPrice)
// Vue.component('contractAudit', contractAudit)
// Vue.component('contractList', contractList)
// Vue.component('addContract', addContract)
// Vue.component('contractCreditAudit', contractCreditAudit)
Vue.prototype.Trim = function (str) {
  return str.replace(/(^\s*)|(\s*$)/g, '');
}
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
const i18n = new VueI18n({
  locale: 'en_US', // 默认英语语言
  messages: {
    'in_ID': require('@/common/lang/id.js'), // 印尼语言包
    'en_US': require('@/common/lang/en.js'), // 英文语言包
    'zh_ZH': require('@/common/lang/zh.js'), // 中文文语言包
  }
})

Vue.directive('test', {
  bind(el, binding, vnode) {
    console.log(el);
    el.style.color = 'black'
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
/*
 * @Author: SongNing
 * @LastEditors: SongNing
 * @Date: 2019-03-29 10:07:47
 * @LastEditTime: 2019-03-29 10:10:29
 */

//导入模块
import * as api_banner from './banner'

const apiObj = {
  api_banner
}

const install = function (Vue) {
  if (install.installed) return
  install.installed = true

  //定义属性到Vue原型中
  Object.defineProperties(Vue.prototype, {
    $fetch: {
      get() {
        return apiObj
      }
    }
  })
}

export default {
  install
}

<template>
  <div>
    <el-menu
      :default-active="activeRouter"
      class="el-menu-vertical-demo"
      :collapse="isCollapse"
      router
      :unique-opened="true"
      @open="handleOpen"
      @close="handleClose"
    >
      <el-submenu index="1">
        <template slot="title">
          <div class="point" />
          <span>Product Management</span>
        </template>
        <el-menu-item index="/Manage/commodity"> Product List </el-menu-item>
        <el-menu-item index="/Manage/add"> Add Product </el-menu-item>
        <el-menu-item index="/Manage/delete"> Delete Product </el-menu-item>
      </el-submenu>

      <el-submenu index="2">
        <template slot="title">
          <div class="point" />
          <span>Enterprise Management</span>
        </template>
        <el-menu-item index="/businessmanagement/Business">
          Enterprise Info List
        </el-menu-item>
        <el-menu-item index="/businessmanagement/createEnterprise">
          Add Enterprise
        </el-menu-item>
        <el-menu-item index="/businessmanagement/enterprise">
          Audit Enterprise Basic Info
        </el-menu-item>
        <el-menu-item index="/businessmanagement/bank">
          Audit Enterprise Bank Info
        </el-menu-item>
        <el-menu-item index="/businessmanagement/invoice">
          Audit Enterprise Invoic Info
        </el-menu-item>
      </el-submenu>
      <el-submenu index="3">
        <template slot="title">
          <div class="point" />
          <span>Contract Management</span>
        </template>
        <el-menu-item index="/contractManage/contractList">
          Contract List
        </el-menu-item>
        <el-menu-item index="/contractManage/addContract">
          Add Contract
        </el-menu-item>
        <el-menu-item index="/contractManage/contractAudit">
          Audit Contract By Business
        </el-menu-item>
        <el-menu-item index="/contractManage/contractCreditAudit">
          Audit Contract by Credit
        </el-menu-item>
      </el-submenu>
      <el-submenu index="4">
        <template slot="title">
          <div class="point" />
          <span>User Management</span>
        </template>
        <el-menu-item index="/user/list"> User List </el-menu-item>
        <el-menu-item index="/user/add"> Add User </el-menu-item>
        <el-menu-item index="/user/changePassword">
          Reset Password
        </el-menu-item>
      </el-submenu>

      <el-submenu index="5">
        <template slot="title">
          <div class="point" />
          <span>Product Pool Management</span>
        </template>
        <el-menu-item index="/productPool/Pool">
          Product Pool List
        </el-menu-item>
        <el-menu-item index="/productPool/add"> Add Product Pool </el-menu-item>
      </el-submenu>
      <el-submenu index="6">
        <template slot="title">
          <div class="point" />
          <span slot="title">Discount Management</span>
        </template>
        <el-menu-item index="/discountManage/discountTable">
          Discount Rate List
        </el-menu-item>
        <el-menu-item index="/discountManage/addDiscount">
          Add Discount Rate
        </el-menu-item>
        <el-menu-item index="/discountManage/auditDiscount">
          Audit Discount Rate
        </el-menu-item>
      </el-submenu>
      <el-submenu index="7">
        <template slot="title">
          <div class="point" />
          <span>Order Management</span>
        </template>
        <el-menu-item index="/modifyPriceManage/modifyPrice">
          Negotiation Order List
        </el-menu-item>
        <el-menu-item index="/modifyPriceManage/orderList">
          Order List
        </el-menu-item>
        <el-menu-item index="/modifyPriceManage/otList">
          Ot List
        </el-menu-item>
      </el-submenu>
      <el-submenu index="8">
        <template slot="title">
          <div class="point" />
          <span>Batch Order Management</span>
        </template>
        <el-menu-item index="/Batch/Order"> Batch Order List </el-menu-item>
        <!-- <el-menu-item index="/Batch/Add"> Add Batch Order </el-menu-item> -->
      </el-submenu>
      <el-submenu index="9">
        <template slot="title">
          <div class="point" />
          <span>Website Management</span>
        </template>
        <el-menu-item index="/web/index"> Banner </el-menu-item>
        <el-menu-item index="/web/promotionItem"> Hot Product </el-menu-item>
        <el-menu-item index="/web/salesPromotion"> Today Promo </el-menu-item>
        <el-menu-item index="/web/brandWall"> Brand Street </el-menu-item>
        <el-menu-item index="/Website/special"> Special Promo </el-menu-item>
        <el-menu-item index="/Website/coustomerConfig">
          Customer Config
        </el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>
<style lang="less" scoped>
::v-deep .el-menu-vertical-demo {
  width: 300px;
}
::v-deep .el-submenu__title {
  height: 50px;
  padding: 0 8px;
  color: rgba(36, 37, 38, 0.45);
  font-weight: Bold;
  .point {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: rgba(36, 37, 38, 0.45);
    display: inline-block;
    margin-right: 8px;
    margin-left: -10px;
  }
  .el-submenu__icon-arrow {
    font-size: 14px;
    top: 30px;
    right: 8px;
  }
}
::v-deep .is-active {
  .el-submenu__title {
    color: rgba(34, 98, 255, 1);
    .point {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: rgba(34, 98, 255, 1);
      display: inline-block;
      margin-right: 8px;
      margin-left: -10px;
    }
  }
  .is-active {
    color: rgba(34, 98, 255, 1);
  }
}

/deep/.sidebar-container {
  ul {
    li {
      div {
        span {
          color: red !important;
        }
      }
    }
  }
}
</style>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      activeRouter: ''
    };
  },

  computed: {
    ...mapGetters({
      isCollapse: 'getCollapseState'
    })
  },
  watch: {
    $route(to, from) {
      this.activeRouter = to.fullPath;
      console.log(to);
    }
  },
  mounted() {
    this.activeRouter = this.$route.path;
  },
  methods: {
    handleOpen() {
      // //console.log(key, keyPath);
    },
    handleClose() {
      // //console.log(key, keyPath);
    }
  }
};
</script>

import Vue from 'vue';
import Router from 'vue-router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import viewPageComponent from './views/App';

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: viewPageComponent,
      // redirect: '/home',
      children: [
        {
          path: '/',
          name: 'home',
          component: () => import('@/views/home')
        }
      ]
    },
    {
      path: '/commodityPools',
      component: viewPageComponent,
      children: [
        {
          path: 'index',
          name: 'VSPCommodity Pool management',
          component: () => import('@/views/commodityPools/index'),
          meta: {
            parent: 'Manages VSP',
            title: 'Manages VSP commodity pools'
          }
        },
        {
          path: 'updataCommodity',
          name: 'editCommodity pools',
          component: () => import('@/views/commodityPools/updataCommodity'),
          meta: { title: 'Updata Commodity' }
        }
      ]
    },
    // 用户管理
    {
      path: '/user',
      component: viewPageComponent,
      children: [
        {
          path: 'list',
          name: 'users list',
          component: () => import('@/views/user/list'),
          meta: { parent: 'User Management', title: 'User List' }
        },
        {
          path: 'changePassword',
          name: 'change password',
          component: () => import('@/views/user/changePassword'),
          meta: {
            parent: 'User Management',
            title: 'Reset Password'
          }
        },
        {
          path: 'add',
          name: 'User Management New/edit',
          component: () => import('@/views/user/add'),
          meta: { parent: 'User Management', title: 'Add User' }
        }
      ]
    },
    // 商城管理
    {
      path: '/web',
      component: viewPageComponent,
      children: [
        {
          path: 'index',
          name: 'webbanner',
          component: () => import('@/views/web/index'),
          meta: { parent: 'Website Management', title: 'Banner' }
        },
        {
          path: 'salesPromotion',
          name: 'product promotion ',
          component: () => import('@/views/web/salesPromotion'),
          meta: {
            parent: 'Website Management',
            title: 'Today Promo '
          }
        },
        {
          path: 'promotionItem',
          name: 'hot commodity',
          component: () => import('@/views/web/promotionItem'),
          meta: {
            parent: 'Website Management',
            title: 'Hot Product'
          }
        },
        {
          path: 'manage',
          name: 'product promotion particulars',
          component: () => import('@/views/web/manage'),
          meta: { parent: 'Website Management', title: 'Brand Street ' }
        },
        {
          path: 'brandWall',
          name: 'brandWall',
          component: () => import('@/views/web/brandWall'),
          meta: {
            parent: 'Website Management',
            title: 'Brand Street'
          }
        }
      ]
    },
    // 企业
    {
      path: '/businessmanagement',
      component: viewPageComponent,
      children: [
        {
          path: 'Business',
          name: 'Business',
          component: () => import('@/views/businessmanagement/Business'),
          meta: {
            parent: 'Enterprise Management',
            title: 'Enterprise Info List'
          }
        },
        {
          path: 'Businesslist',
          name: 'Businesslist',
          component: () => import('@/views/businessmanagement/Businesslist'),
          meta: {
            parent: 'Enterprise Management',
            title: 'Enterprise Information Details'
          }
        },
        {
          path: 'createEnterprise',
          name: 'createEnterprise',
          component: () =>
            import('@/views/businessmanagement/createEnterprise'),
          meta: {
            parent: 'Enterprise Management',
            title: 'Add Enterprise'
          }
        },
        {
          path: 'bank',
          name: 'bank',
          component: () => import('@/views/businessmanagement/bank'),
          meta: {
            parent: 'Enterprise Management',
            title: 'Audit Enterprise Bank Info'
          }
        },
        {
          path: 'enterprise',
          name: 'enterprise',
          component: () => import('@/views/businessmanagement/enterprise'),
          meta: {
            parent: 'Enterprise Management',
            title: 'Audit Enterprise Basic Info'
          }
        },
        {
          path: 'enterpriseInfo',
          name: 'enterpriseInfo',
          component: () => import('@/views/businessmanagement/enterpriseInfo'),
          meta: {
            parent: 'Enterprise Management',
            title: 'Enterprise information audit '
          }
        },
        {
          path: 'bankInfo',
          name: 'bankInfo',
          component: () => import('@/views/businessmanagement/bankInfo'),
          meta: {
            parent: 'Enterprise Management',
            title: 'Review the details of enterprise information'
          }
        },
        {
          path: 'invoiceinfo',
          name: 'invoiceinfo',
          component: () => import('@/views/businessmanagement/invoiceinfo')
        },
        {
          path: 'invoice',
          name: 'invoice',
          component: () => import('@/views/businessmanagement/invoice'),
          meta: {
            parent: 'Enterprise Management',
            title: 'Audit Enterprise Invoic Info'
          }
        }
      ]
    },
    // 折扣率
    {
      path: '/discountManage',
      component: viewPageComponent,
      children: [
        {
          path: 'discountTable',
          name: 'List of discount rates',
          component: () => import('@/views/discountManage/discountTable'),
          meta: {
            parent: 'Discount Rate Management',
            title: 'Discount Rate List '
          }
        },
        {
          path: 'addDiscount',
          name: 'New discount rate',
          component: () => import('@/views/discountManage/addDiscount'),
          meta: {
            parent: 'Discount Rate Management',
            title: 'Add Discount Rate'
          }
        },
        {
          path: 'auditDiscount',
          name: 'auditDiscount',
          component: () => import('@/views/discountManage/auditDiscount'),
          meta: {
            parent: 'Discount Rate Management',
            title: 'Audit Discount Rate'
          }
        }
      ]
    },
    // 合同
    {
      path: '/contractManage',
      component: viewPageComponent,
      children: [
        {
          path: 'contractList',
          name: 'contract list',
          component: () => import('@/views/contractManage/contractList'),
          meta: {
            parent: 'Contract Management',
            title: 'Contract List'
          }
        },
        {
          path: 'contractAudit',
          name: 'contract review',
          component: () => import('@/views/contractManage/contractAudit'),
          meta: {
            parent: 'Contract Management',
            title: 'Audit Contract By Business'
          }
        },
        {
          path: 'addContract',
          name: 'Create a contract',
          component: () => import('@/views/contractManage/addContract'),
          meta: {
            parent: 'Contract Management',
            title: 'Add Contract'
          }
        },
        {
          path: 'checkContract',
          name: 'Look at the contract',
          component: () => import('@/views/contractManage/checkContract'),
          meta: {
            parent: 'Contract Management',
            title: 'Look at the contract'
          }
        },
        {
          path: 'contractCreditAudit',
          name: 'Review contract financial information',
          component: () => import('@/views/contractManage/contractCreditAudit'),
          meta: {
            parent: 'Contract Management',
            title: 'Audit Contract by Credit'
          }
        }
      ]
    },
    // 改价
    {
      path: '/modifyPriceManage',
      component: viewPageComponent,
      children: [
        {
          path: 'modifyPrice',
          name: 'Change price list',
          component: () =>
            import('@/views/modifyPriceManage2/modeifyPrice.vue'),
          meta: {
            parent: 'Order Management',
            title: 'Negotiation Order List'
          }
        },
        {
          path: 'modifyOrderPrice',
          name: 'Modify order price',
          component: () =>
            import('@/views/modifyPriceManage2/modifyOrderPrice.vue'),
          meta: {
            parent: 'Order Management',
            title: 'Modify order price'
          }
        },
        {
          path: 'orderList',
          name: 'Order to check the',
          component: () => import('@/views/modifyPriceManage2/orderList.vue'),
          meta: {
            parent: 'Order Management',
            title: 'Order List'
          }
        },
        {
          path: 'otList',
          name: '',
          component: () => import('@/views/modifyPriceManage2/otList.vue'),
          meta: {
            parent: 'Order Management',
            title: 'Ot List'
          }
        }
      ]
    },
    // 商品管理
    {
      path: '/Manage',

      component: viewPageComponent,
      children: [
        {
          path: 'commodity',
          name: 'demo列表',
          component: () => import('@/views/VspProduct/commodity/commodity'),
          meta: {
            parent: 'Product Management',
            title: 'Product List'
          }
        },
        {
          path: 'detail/:id',
          name: 'demoparticulars',
          component: () => import('@/views/demo/detail'),
          meta: {
            parent: 'demoparticulars VSP',
            title: 'demoparticulars'
          }
        },
        {
          path: 'add',
          name: 'commodity商品',
          component: () => import('@/views/VspProduct/AddProduct/addProduct'),
          meta: { parent: 'Product Management', title: 'Add Product' }
        },
        {
          path: 'delete',
          name: 'commodity删除',
          component: () => import('@/views/VspProduct/delete/deleteProduct'),
          meta: { parent: 'Product Management', title: 'Delete Product' }
        }
      ]
    },
    // 商品池
    {
      path: '/productPool',
      component: viewPageComponent,
      children: [
        {
          path: 'Pool',
          name: 'Commodity Pool management',
          component: () =>
            import('@/views/ProductPool/ProductPoolList/ProductPool'),
          meta: {
            parent: 'Product Pool Management',
            title: 'Product Pool List'
          }
        },
        {
          path: 'add',
          name: 'Commodity pools',
          component: () => import('@/views/commodityPools/add'),
          meta: {
            parent: 'Product Pool Management',
            title: 'Add Product Pool '
          }
        }
      ]
    },
    // web管理
    {
      path: '/Website',
      component: viewPageComponent,
      children: [
        {
          path: 'special',
          name: 'special',
          component: () =>
            import('@/views/websiteManage/specialPromo/special.vue'),
          meta: {
            parent: 'Website Management',
            title: 'Special Promo'
          }
        },
        {
          path: 'coustomerConfig',
          name: 'Coustomer',
          component: () =>
            import('@/views/websiteManage/specialPromo/customerConfig'),
          meta: {
            parent: 'Website Management',
            title: 'Customer Config'
          }
        }
      ]
    },
    // 批量下单
    {
      path: '/Batch',
      component: viewPageComponent,
      children: [
        {
          path: 'Order',
          name: 'Order',
          component: () => import('@/views/batchOrderManage/index.vue'),
          meta: {
            parent: 'Batch Order Management',
            title: 'Batch Order List'
          }
        },
        {
          path: 'Add',
          name: 'Add',
          component: () => import('@/views/batchOrderManage/add.vue'),
          meta: {
            parent: 'Batch Order Management',
            title: 'Add Batch Order'
          }
        }
      ]
    }
  ]
});
//路由开始之前的操作
router.beforeEach((to, from, next) => {
  NProgress.done().start();
  next();
  // Todo 权限判断
});

//路由完成之后的操作
router.afterEach(() => {
  NProgress.done();
});
export default router;

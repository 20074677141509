import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isCollapse: false,
    Delete: null,
    tableData: null,
    checkId: [], //商品管理的选择的id
    isFinit: null,
    // 折扣页 单选按钮标识
    isResourceFlag: 1
  },
  getters: {
    getCollapseState(state) {
      return state.isCollapse;
    },
    getIsResourceFlag(state) {
      return state.isResourceFlag;
    }
  },
  mutations: {
    setCollapseState(state) {
      state.isCollapse = !state.isCollapse;
    },
    // 商品管理选择框id填入
    addList(state, data) {
      state.checkId = data;
      //console.log(data);
    },
    // 当前表格数据
    tableDatas(state, data) {
      state.tableData = data;
    },
    setIsResourcFlag(state, data) {
      state.isResourceFlag = data;
    }

  },
  actions: {}
});

<template>
  <div class="uploadPic">
    <div class="piclist">
      <ul class="upload-list">
        <li
          v-for="(item, index) in imgArr"
          :key="index"
          class="picItem picAdded"
        >
          <i class="el-icon-delete" @click="deleteClick(index)" />
          <label class="el-upload-list__item-status-label">
            <i class="el-icon-upload-success el-icon-check" />
          </label>
          <img class="picImg" :src="item" width="148px" height="148px">
        </li>
        <li
          v-show="imgArr.length < maxPostImgs"
          v-loading="load_data"
          class="picItem"
        >
          <div class="upload">
            <i class="el-icon-plus" />
            <input
              :id="fileId"
              type="file"
              name="uploadFiles"
              class="upload__input"
              @change="picAdd"
            >
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  props: {
    id: {
      type: Number,
      default: 0
    },
    maxPostImgs: {
      type: Number,
      default: 1
    },
    postImgUrl: {
      type: String,
      default: ''
    },
    defaultImgs: {
      type: Array,
      default() {
        return []
      }
    },
    width: {
      type: Number,

    },
    height: {
      type: Number
    },
    baseUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      imgArr: [],
      disableds: false,
      load_data: false
    }
  },
  computed: {
    fileId() {
      return `file${this.id}`
    }
  },
  watch: {
    defaultImgs() {
      this.imgArr = this.defaultImgs
      this.load_data = false
    }
  },
  created() {
    this.imgArr = this.defaultImgs
  },
  methods: {
    deleteClick(index) {
      this.load_data = false
      this.imgArr.splice(index, 1)
      this.defaultImgs = this.imgArr
    },
    picAdd() {
      let that = this
      // document.getElementById(that.fileId).setAttribute('disabled',true)
      this.load_data = true
      // var input = event.target;
      var f = document.getElementById(that.fileId).value
      // window.//console.log(document.getElementById(that.fileId).files[0].width)
      if (f == '') {
        this.$message.error('Please upload pictures')
        return false
      } else {
        if (!/\.(gif|jpg|jpeg|png|GIF|JPG|PNG|png)$/.test(f)) {
          this.$message.error('图片类型必须是.gif,jpeg,jpg,png中的一种')
          this.load_data = false
          return false
        }
      }
      if(this.width&&this.height){
        this.getImgInfo(document.getElementById(that.fileId)).then((res)=>{
          if(!res){
            this.load_data = false
            return false
          }else{
            this.uploadData()
          }
        })
      }else{
        this.uploadData()
      }



    },
    uploadData () {
      var that = this
      var formdata = new FormData()
      formdata.append(
        'imageFile',
        document.getElementById(this.fileId).files[0]
      )
      formdata.append('imageFileType', '1')
      this.httpHelper({
        type: 'post',
        async: 'true',
        data: formdata,
        url: `${that.baseUrl}/json/file/upload/file_uploadPic.action`,
        success: function(data) {
          //上传成功
          // let data = JSON.parse(data)
          that.defaultImgs.push(
            `https://img30.360buyimg.com/pop/${data.message}`
          )
          document.getElementById(that.fileId).value = ''

          this.load_data = false
        },
        error: function() {
          //上传失败
          document.getElementById(that.fileId).value = ''
          this.load_data = false
        }
      })
    },
    httpHelper(params) {
      var request
      if (XMLHttpRequest) request = new XMLHttpRequest()
      else request = new ActiveXObject('Microsoft.XMLHTTP') // eslint-disable-line


      request.withCredentials = true
      request.onreadystatechange = function() {
        if (request.readyState == 4) {
          if (request.status == 200) {
            if (params.success) params.success(request.responseText)
          } else if (parseInt(request.status / 100) == 4) {
            if (params.error) params.error(request.responseText)
          }
        }
      }
      request.open(params.type, params.url, params.async)
      request.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
      try {
        request.send(params.data || null)
      } catch (e) {
        if (params.error) params.error(request.responseText)
      }
    },
    getImgInfo(ev) {
      return new Promise((resolve) => {
        var that = this
        var oFile = ev.files[0]
        var reader = new FileReader()
        reader.onload = function() {
          var oImg = new Image()
          oImg.src = this.result
          document.body.appendChild(oImg)

          oImg.onload = function() {
            var imgWidth = oImg.offsetWidth
            var imgHeight = oImg.offsetHeight
            if (imgWidth != that.width || imgHeight != that.height) {
              that.$message.error(`Please upload${that.width}*${that.height}size picture`)
              resolve(false)
            } else {
              resolve(true)
            }
            document.body.removeChild(oImg)
          }
        }
        reader.readAsDataURL(oFile)
      })
    }
  }
}
</script>
<style lang="less" type="text/less" rel="stylesheet/less">
.uploadPic {
  display: flex;
  /*background: gainsboro;*/
  .picItem {
    float: left;
    margin-right: 5px;
    position: relative;
    text-align: center;
    font-size: 18px;
    color: red;
    .upload {
      position: relative;
      background-color: #fbfdff;
      border: 1px dashed #c0ccda;
      border-radius: 6px;
      box-sizing: border-box;
      width: 148px;
      height: 148px;
      cursor: pointer;
      line-height: 146px;
      vertical-align: top;
      text-align: center;
      .upload__input {
        cursor: pointer;
        opacity: 0;
        position: absolute;
        left: 0px;
        top: 0px;
        background: goldenrod;
        width: 148px;
        height: 148px;
        z-index: 10;
      }
      .el-icon-plus:before {
        content: '\E61C';
        color: #8c939d;
        font-size: 28px;
      }
    }
    .el-icon-delete {
      position: absolute;
      width: 40px;
      height: 40px;
      left: 0;
      right: 0;
      top: 12px;
      bottom: 0;
      margin: auto;
      font-weight: 400;
      display: none;
    }
    //   .el-icon-delete:before{
    //     content: "\E624";
    //   }
    .picImg {
      border-radius: 8px;
    }
  }

  .picAdded:hover {
    .picImg {
      filter: alpha(Opacity=0);
    }
    .el-icon-delete {
      display: block;
    }
  }
}
</style>

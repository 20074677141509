import fetch from 'common/fetch';
// 退出按钮
export function loginOut(params) {
  return fetch({
    url: '/logout',
    method: 'post',
    params
  });
}

//getPin
export function getPin() {
  return fetch({
    url: '/system/user/getPin',
    method: 'get',
  })
}

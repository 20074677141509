<template>
  <footer
    v-once
    class="footer"
  >
    <div>
      {{ text }}
    </div>
  </footer>
</template>
<script type="text/javascript">
export default {
  data() {
    return{
      text: 'Copyright Aviator © 2024',
    }
  },
  computed: {
    getYear(){
      return new Date().getFullYear()
    }
  }
}
</script>
<style lang="less" scoped>
.footer {
    z-index:999;
    background: #fff;
    padding: 15px;
    color: #7a7676;
    font-size: 12px;
    /* position: fixed;
    bottom: 0; */
    width: 100%;
    border-top: 1px solid #eff0f4;
    text-align: center;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
</style>

import fetch from 'common/fetch'

//get请求
export function getBannerList(params) {
  return fetch({
    url: '接口地址',
    method: 'get',
    params
  })
}
// restful风格接口参数拼接
export function deleteBanner(id, params) {
  return fetch({
    url: `接口地址/${id}`,
    method: 'delete',
    params
  })
}
//post请求
export function addBanner(params) {
  return fetch({
    url: '接口地址',
    method: 'post',
    data : params
  })
}

/*
 * @Author: SongNing
 * @LastEditors: SongNing
 * @Date: 2019-03-29 09:56:19
 * @LastEditTime: 2021-11-16 21:27:41
 */

//导入模块
import axios from 'axios';
// import router from 'src/router'
import { Message, Loading } from 'element-ui';
import _ from 'lodash';

//loading对象
let loading;

//当前正在请求的数量
let needLoadingRequestCount = 0;

//显示loading
function showLoading(target) {
  // 后面这个判断很重要，因为关闭时加了抖动，此时loading对象可能还存在，
  // 但needLoadingRequestCount已经变成0.避免这种情况下会重新创建个loading
  if (needLoadingRequestCount === 0 && !loading) {
    loading = Loading.service({
      lock: true,
      text: 'Loading...',
      background: 'rgba(255, 255, 255, 0.5)',
      target: target || 'body'
    });
  }
  needLoadingRequestCount++;
}

//隐藏loading
function hideLoading() {
  needLoadingRequestCount--;
  needLoadingRequestCount = Math.max(needLoadingRequestCount, 0); //做个保护
  if (needLoadingRequestCount === 0) {
    //关闭loading
    toHideLoading();
  }
}
//防抖：将 300ms 间隔内的关闭 loading 便合并为一次。防止连续请求时， loading闪烁的问题。
var toHideLoading = _.debounce(() => {
  loading.close();
  loading = null;
}, 300);

function GetUrlParam(paraName) {
  var url = document.location.toString();
  var arrObj = url.split('?');
  if (arrObj.length > 1) {
    var arrPara = arrObj[1].split('&');
    var arr;
    for (var i = 0; i < arrPara.length; i++) {
      arr = arrPara[i].split('=');

      if (arr != null && arr[0] === paraName) {
        return arr[1];
      }
    }
    return '';
  } else {
    return '';
  }
}
let Query = GetUrlParam('sso_service_ticket');


export default function fetch(options) {
  return new Promise((resolve, reject) => {
    let arrHeader;
    arrHeader = {
      'Content-Type': 'application/json;charset=utf-8',
      'X-Requested-With': 'XMLHttpRequest',
      'X-API-Timestamp': new Date().getTime(),
      'Sso-Service-Ticket': Query || null
    };
    //创建一个axios实例
    const instance = axios.create({
      //设置默认根地址
      baseURL: process.env.VUE_APP_BASE_API,
      //设置请求超时设置
      timeout: 30000,
      //设置请求时的header
      headers: arrHeader,
      //允许跨域携带cookie
      withCredentials: true
    });
    //添加请求拦截器
    instance.interceptors.request.use(config => {
      //判断当前请求是否设置了不显示Loading
      if (config.headers.showLoading !== false) {
        showLoading(config.headers.loadingTarget);
      }
      return config;
    }, err => {
      //判断当前请求是否设置了不显示Loading
      if (err.headers.showLoading !== false) {
        hideLoading();
      }
      // Message.error('Request timeout!');
      return Promise.resolve(err);
    });

    //添加一个响应拦截器
    instance.interceptors.response.use(
      function (res) {
        if (res.data && res.data.code === 999) {
          // 调转登录
          let currentUrl = encodeURIComponent(window.location.href);
          window.location =
            process.env.VUE_APP_LOGINURL + 'ReturnUrl=' + currentUrl;
        }
        if (res.config.headers.showLoading !== false) {
          hideLoading();
        }

        return res;
      },
      function (error) {
        if (error.config.headers.showLoading !== false) {
          hideLoading();
        }
        return Promise.reject(error);
      }
    );

    //请求处理
    instance(options)
      .then(res => {
        //请求成功时,根据业务判断状态
        if (res.data) {
          resolve(res.data);
          return false;
        } else {
          resolve(res.data);
          return false;
        }
      })
      .catch(error => {
        //请求失败时,根据业务判断状态
        if (error.response) {
          reject(error.data);
          if (error.response.status === 401) {
            let currentUrl = encodeURIComponent(window.location.href);
            window.location =
              process.env.VUE_APP_LOGINURL + 'ReturnUrl=' + currentUrl;
          }
        }
      });
  });
}
